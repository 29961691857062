import _reactDomServerLegacyBrowserProductionMin from "./cjs/react-dom-server-legacy.browser.production.min.js";
import _reactDomServerBrowserProductionMin from "./cjs/react-dom-server.browser.production.min.js";
var exports = {};
var l, s;
{
  l = _reactDomServerLegacyBrowserProductionMin;
  s = _reactDomServerBrowserProductionMin;
}
exports.version = l.version;
exports.renderToString = l.renderToString;
exports.renderToStaticMarkup = l.renderToStaticMarkup;
exports.renderToNodeStream = l.renderToNodeStream;
exports.renderToStaticNodeStream = l.renderToStaticNodeStream;
exports.renderToReadableStream = s.renderToReadableStream;
export default exports;
export const version = exports.version,
      renderToString = exports.renderToString,
      renderToStaticMarkup = exports.renderToStaticMarkup,
      renderToNodeStream = exports.renderToNodeStream,
      renderToStaticNodeStream = exports.renderToStaticNodeStream,
      renderToReadableStream = exports.renderToReadableStream;